header {
  display: flex;
}
header ul {
  background-color: #1a2327;
  padding: 20px;
  padding-right: 100px;
  display: flex;
  justify-content: right;
  gap: 30px;
  width: 100%;
  font-size: 1.5em;
}
header ul .menu {
  display: none;
}
header ul li {
  list-style-type: none;
  display: flex;
  align-items: center;
}
header ul li a, header ul li .myLink {
  text-decoration: none;
}
header ul li a:hover {
  color: #bdc1c1;
}

@media screen and (max-width: 999px) {
  header ul li {
    font-size: 1.25em;
  }
}
@media screen and (max-width: 750px) {
  header ul li:not(.menu) {
    display: none;
  }
  header ul .menu {
    display: block;
  }
  header ul {
    display: block;
  }
  header ul li {
    font-size: 1.25em;
    margin-bottom: 3px;
    background-color: rgba(32, 42, 46, 0.8941176471);
    padding: 15px;
  }
}/*# sourceMappingURL=Header.css.map */