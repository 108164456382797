$black: #1a2327;
$red: #d9534f;
header{
    display: flex;
    ul{
        background-color: #1a2327;
        padding: 20px;
        padding-right: 100px;
        display: flex;
        justify-content: right;
        gap: 30px;
        width: 100%;
        font-size: 1.5em;
        .menu{
            display: none;
        }
        li{
            list-style-type: none;
            display: flex;
            align-items: center;
            a, .myLink{
                text-decoration: none;
            }
            a:hover{
                color: #bdc1c1;
            }
        }
    }
}
@media screen and (max-width: 999px) {
   
    header ul li {
        font-size: 1.25em;
    }
}
@media screen and (max-width: 750px) {
    header ul li:not(.menu) {
        display: none;
    }
    header ul .menu{
        display: block;
    }
    header ul {
        display: block;
    }
    header ul li {
        font-size: 1.25em;
        margin-bottom: 3px;
        background-color: #202a2ee4;
        padding: 15px;
    }
}