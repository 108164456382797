* {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

body {
  background-color: #1a2327;
}
body .container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
}
body .container .contactDiv {
  width: 700px;
  height: 700px;
  background-color: #0e1315;
  transition: 1s;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .container .contactDiv .sub {
  content: "";
  position: absolute;
  width: 1000px;
  height: 380px;
  background-color: transparent;
  overflow: hidden;
  transition: 2s;
  background: linear-gradient(cyan, orange);
  animation: rotate 3s linear infinite;
}
body .container .contactDiv .contact {
  position: absolute;
  z-index: 10;
  text-align: center;
  font-size: 1.5em;
}
body .container .contactDiv .contact p {
  color: rgb(255, 255, 255);
}
body .container .contactDiv::after {
  content: "";
  position: absolute;
  width: 695px;
  height: 695px;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background-color: #0e1315;
}

@media screen and (max-width: 1000px) {
  body header ul {
    font-size: 1em;
  }
}
@media screen and (max-width: 750px) {
  body header ul {
    padding-right: 20px;
    gap: 20px;
    justify-content: center;
  }
  body .container {
    font-size: 1em;
  }
  body .container .contactDiv {
    width: 300px;
    height: 300px;
  }
  body .container .contactDiv .sub {
    width: 100px;
  }
  body .container .contactDiv::after {
    width: 295px;
    height: 295px;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
    filter: hue-rotate(0deg) blur(50px);
  }
  100% {
    transform: rotate(360deg);
    filter: hue-rotate(360deg) blur(50px);
  }
}/*# sourceMappingURL=contact.css.map */