.name{
    color: cyan;
    transition: 1s;
    animation: animate ease-in-out 3s infinite;
}
@keyframes animate {
    0%, 100%{
        text-shadow: 0 0 8px rgb(88, 136, 136);
    }
    50%{
        text-shadow: 0 0 10px cyan;
    }
}