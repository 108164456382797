$black: #1a2327;
$red: #d9534f;
* {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}
body {
  background-color: $black;
  .topContainer {
    position: relative;
    z-index: 0;
    height: 60vh;
    background-color: #263136;
    overflow: hidden;
    .hexDiv {
      background-color: transparent;
      position: absolute;
      overflow: hidden;
      width: 100%;
      z-index: 2;
      height: 100%;
      .divs {
        display: inline-flex;
        width: 100%;
        justify-content: flex-end;
        .hex {
          width: 5vw;
          height: 5vw;
          margin-top: -1.325vw;
          background-color: rgba(0, 255, 255, 0.7);
          clip-path: polygon(
            25% 0%,
            75% 0%,
            100% 50%,
            75% 100%,
            25% 100%,
            0% 50%
          );
          transform: rotate(90deg);
          margin-right: 0.2vw;
          transition: 2s;
          animation: animate 4s linear infinite;
        }
      }
      .divs:nth-child(even) {
        margin-left: 2.6vw;
      }
    }

    .intros {
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      height: 100%;
      .intro {
        // background-color: green;
        & p {
          font-size: 4em;
          font-weight: 900;
          letter-spacing: 2.5px;
          margin-left: 200px;
          margin-bottom: 20px;
        }
        #subIntro {
          font-size: 1.5em;
        }
      }
      .imgDiv {
        // background-color: blue;
        height: 100%;
        display: flex;
        align-items: end;
        img {
          width: 30vw;
        }
      }
    }
  }
  .toHide:nth-child(3) {
    display: none;
  }
  .midContainer {
    display: flex;
    justify-content: center;
    padding: 30px 400px;
    letter-spacing: 2px;
    gap: 10px;
    .questionDiv {
      width: 400px;
      padding-right: 5px;
      & p:not(#successMessage) {
        font-size: 2em;
        margin-bottom: 10px;
        font-weight: bold;
      }
      #successMessage {
        background-color: aqua;
        color: $black;
        padding: 5px;
        border-radius: 3vw;
        padding-left: 10px;
      }
      & input {
        width: 350px;
        height: 50px;
        margin-bottom: 20px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid white;
        color: white;
        font-size: 1.25em;
        outline: none;
        padding-left: 20px;
      }
      & .message {
        font-size: 1.25em;
        box-sizing: border-box;
        color: $black;
        height: 100px;
        width: 370px;
        margin-bottom: 20px;
        padding: 5px;
        outline: $black;
      }
      & .message:focus {
        border: 2px solid $black;
        box-shadow: 0 0 4px white;
      }
      & button {
        width: 150px;
        height: 50px;
        color: white;
        border: none;
        font-size: 1.5em;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5vw;
      }
      & button:hover {
        background-color: white;
        color: #292b2c;
        padding: 5px;
      }
    }
    .Me {
      border-left: 1px solid white;
      width: 400px;
      padding-left: 50px;
      #about {
        font-size: 2.5em;
        font-weight: bold;
      }
      & p {
        font-weight: bold;
        font-size: 1.5em;
        margin-bottom: 30px;
        margin-left: 0;
        span {
          font-weight: 100;
        }
      }
    }
  }
}
@keyframes animate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
@keyframes successAnimate {
  0% {
    display: block;
  }
  100% {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  body .topContainer .intros .intro p {
    font-size: 3em;
    margin-left: 40px;
  }
  body .topContainer .intros .intro #subIntro {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 1000px) {
  body header ul {
    font-size: 1em;
  }
  body .topContainer .intros {
    display: block;
    justify-content: center;
    width: 100%;
  }
  body .topContainer .intros .intro p {
    margin-left: 0;
    font-size: 1.5em;
    text-align: center;
  }
  body .topContainer .intros .intro #subIntro {
    font-size: 1em;
  }
  body .topContainer .intros .imgDiv {
    position: relative;
    min-height: 35vw;
  }
  body .topContainer .intros .imgDiv img {
    width: 700px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
  }
  body .topContainer {
    padding: 10px;
  }
  body .midContainer {
    padding: 30px;
  }
}
@media screen and (max-width: 999px) {
  body .topContainer {
    padding: 0;
  }
  body .topContainer .hexDiv .divs .hex {
    width: 10vw;
    height: 10vw;
    margin-top: -2.5vw;
  }
  body .topContainer .intros .intro {
    background-color: #1a2327;
    padding: 20px;
  }
  body .topContainer .intros .intro p {
    font-size: 2em;
  }
  body .topContainer .intros .intro #subIntro {
  }
  body .midContainer .questionDiv button {
    font-size: 1em;
  }
  body .topContainer .hexDiv .divs:nth-child(even) {
    margin-left: 5vw;
  }
}
@media screen and (max-width: 750px) {
  body .topContainer .intros .intro {
    display: none;
  }
  body .topContainer .intros .intro p {
    padding: 3px;
  }
  body div#root div div div.intro.toHide {
    padding: 20px;
  }
  body div#root div div div.intro.toHide p {
    font-size: 2em;
    letter-spacing: 2.5px;
  }
  body div#root div div div.intro.toHide p#subIntro {
    font-size: 1em;
  }
  body header ul {
    padding-right: 20px;
    gap: 20px;
    justify-content: center;
  }
  body .topContainer {
    height: 30vh;
  }
  body .toHide:nth-child(3) {
    display: block;
  }
  body .topContainer .intros .imgDiv img {
    width: 600px;
  }
  body .midContainer .questionDiv .message {
    width: 280px;
  }
  body .midContainer {
    display: block;
  }
  body .midContainer .Me {
    border: none;
    padding: 0;
    margin-top: 50px;
    width: 100%;
  }
  body .midContainer .questionDiv {
    width: 100%;
    display: block;
  }
  body .midContainer .questionDiv p {
    font-size: 1.5em;
  }
  body .midContainer .Me #about {
    font-size: 1.5em;
  }
  body .midContainer .Me p,
  body .midContainer .questionDiv input,
  body .midContainer .questionDiv button {
    font-size: 1em;
  }
  body .midContainer .questionDiv input {
    width: 250px;
  }
  body .topContainer {
    padding: 0;
  }
}
