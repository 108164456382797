* {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

body {
  background-color: #1a2327;
}
body .headerContainer {
  font-size: 1.5em;
}
body .headerContainer ul {
  padding: 20px;
}
body .headerContainer ul li {
  padding: 20px;
  border: 1px solid gray;
  list-style-type: none;
  margin-bottom: 10px;
  box-shadow: 0 0 3px 3px #10171b;
}

@media screen and (max-width: 1000px) {
  body header ul {
    font-size: 1em;
  }
}
@media screen and (max-width: 750px) {
  body header ul {
    padding-right: 20px;
    gap: 20px;
    justify-content: center;
  }
  body .container {
    font-size: 1em;
  }
}/*# sourceMappingURL=work.css.map */